<template v-slot:activator="{ on, attrs }">
<v-card class="ma-0 pa-0">
    <v-form ref="contactForm">
    <v-card-title class="ma-0 pa-0">
        <v-row class="ma-3" justify="space-between">
            <h4 class="ma-0 pa-0 ml-1">{{ $t("contactForm.title").toUpperCase() }}</h4>
            <v-btn @click="closeDialog()" class="ma-0 pa-0" color="red" icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
    </v-card-title>
    <v-row class="my-1 mx-6" justify="center">
        <v-col hidden cols="10">
            <v-alert class="py-4 px-6" color="orange">
            <v-icon class="mb-1 mr-2">mdi-alert</v-icon>
            <span v-html="$t('contactForm.WIP')" class="">
            </span>
            </v-alert>
        </v-col>
        <v-col cols="12">
            <!-- EMAIL -->
            <v-text-field
            :rules="[this.fieldRules(contact.email, 'ge_email', 'required')]"
            outlined
            validate-on-blur
            :label="$t('contactForm.email')"
            v-model="contact.email">
            </v-text-field>
            <!-- NAME -->
            <v-text-field
            :rules="[this.fieldRules(contact.name, 'ge_name', 'required')]"
            outlined
            validate-on-blur
            :label="$t('contactForm.name')"
            v-model="contact.name">
            </v-text-field>
            <!-- TOPIC -->
            <v-text-field
            :rules="[this.fieldRules(contact.topic, 'ge_topic', 'required')]"
            outlined
            validate-on-blur
            :label="$t('contactForm.topic')"
            v-model="contact.topic">
            </v-text-field>
            <!-- MESSAGE -->
            <v-textarea
            :rules="[this.fieldRules(contact.message, 'ge_message', 'required')]"
            outlined
            validate-on-blur
            :label="$t('contactForm.message')"
            v-model="contact.message"
            auto-grow>
            </v-textarea>
        </v-col>
    </v-row>
    <v-card-actions class="pa-1 ma-1">
        <v-row class="pa-0 ma-0" justify="space-between" align="center">
            <v-scale-transition>
                <v-progress-linear 
                    v-if="hCaptcha.verified && !submitted" 
                    :color="getStatusColor()" 
                    :indeterminate="!hCaptcha.verified"
                    :value="!hCaptcha.verified ? 0 : 100"
                    class="mx-5 my-2">
                </v-progress-linear>
            </v-scale-transition>
            <v-slide-x-transition>
                <v-chip class="bg-valid text-white pa-0 ma-0 ml-3 px-3 py-1" v-if="hCaptcha.verified == true && !error">
                    <span class="font-weight-medium text-uppercase pa-0 ma-0">
                        {{ $t("contactForm.captcha.verified") }}
                    </span>
                    <v-icon small class="pa-0 ma-0 ml-1 mb-1">mdi-check</v-icon>
                </v-chip>
                <v-chip class="bg-error text-white pa-0 ma-0 ml-3 px-3 py-1" v-if="hCaptcha.error == true && !error">
                    <span class="font-weight-medium text-uppercase pa-0 ma-0">
                        {{ $t("contactForm.captcha.error") }}
                    </span>
                    <v-icon small class="pa-0 ma-0 ml-1 mb-1">mdi-close-circle</v-icon>
                </v-chip>
                <v-chip color="orange" class="text-white pa-0 ma-0 ml-3 px-3 py-1" v-if="hCaptcha.expired == true && !error">
                    <span class="font-weight-medium text-uppercase pa-0 ma-0">
                        {{ $t("contactForm.captcha.expired") }}
                    </span>
                    <v-icon small class="pa-0 ma-0 ml-1 mb-1">mdi-alert-circle</v-icon>
                </v-chip>
                <v-chip class="bg-error text-white pa-0 ma-0 ml-3 px-3 py-1" v-if="error == true">
                    <span class="font-weight-medium text-uppercase pa-0 ma-0">
                        {{ $t("contactForm.backendError") }}
                    </span>
                    <v-icon small class="pa-0 ma-0 ml-1 mb-1">mdi-close-circle</v-icon>
                </v-chip>
            </v-slide-x-transition>
            <vue-hcaptcha
            @verify="onVerify"
            @expired="onExpire"
            @challenge-expired="onChallengeExpire"
            @error="onError"
            :language="getISO639_2_Code(this.$i18n.locale)"
            class="pa-0 ma-0"
            size="invisible" 
            id="hCaptcha"
            ref="invisibleHcaptcha"
            :theme="$vuetify.theme.dark ? 'dark' : 'light'"
            :sitekey="hCaptcha.siteKey">
            </vue-hcaptcha>

            <v-spacer/>


            <v-checkbox 
            readonly
            @click="onSubmit"
            style="transform: translateY(23%);"
            :class="(hCaptcha.verified == true ? 'clr-valid' : '' ) + ' ma-0 pa-0 mr-1'"
            :label="$t('contactForm.doCaptcha')" 
            :indeterminate="hCaptcha.expired"
            :error="hCaptcha.error"
            v-model="hCaptcha.verified"
            ></v-checkbox>

            <v-divider vertical class="my-4 mx-4"/>
            <v-btn :disabled="!hCaptcha.verified || submitted == true" class="ma-2" color="primary" @click="submitMessage">
                {{ $t("contactForm.submit") }}
                <v-icon>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
        </v-row>
    </v-card-actions>
    </v-form>
</v-card>
</template>

<style>
#hCaptcha iframe{
    width: fit-content;
    max-height: 100px;
}

</style>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import validationMixin from "@/plugins/mixin/validationMixin";

export default {
    mixins: [validationMixin],
    data() {
        return {
            error: null,
            loading: false,
            submitted: false,
            hCaptcha: {
                verified: false,
                expired: false,
                token: null,
                eKey: null,
                error: null,
                siteKey: "1596de60-8502-4dbc-801f-372692f91723",
            },
            // hCaptchaSecret: "0x97a06eeF031Ed7c86568ad7F5065dDB0B637cBde",
            contact: {
                email: "",
                name: "",
                topic: "",
                message: ""
            }
        };
    },
    methods: {
        getStatusColor() {
            if (this.hCaptcha.verified && !(this.hCaptcha.error || this.error)) {
                return 'valid'
            }
            else if (this.hCaptcha.expired) {
                return 'orange'
            } 
            else if (this.hCaptcha.error || this.error) {
                return 'error'
            }
            return 'primary'
        },
        closeDialog(success) {
            this.$emit("closeDialog");
            if (success) {
                this.emitSnackbar("contactForm.snackbar.success", "mdi-checkbox-marked-circle-outline", "green", "text-white");
            }
        },
        emitSnackbar(msgString, icon, color, snackbarClasses) {
            this.$emit('createSnackbar', msgString, icon, color, snackbarClasses);
        },
        getISO639_2_Code(locale) {
            switch (locale) {
                case 'es':
                    return 'spa'
                case 'en':
                    return 'eng'
                default:
                    return locale
            }
        },
        onVerify(token, ekey) {
            this.hCaptcha.expired = false;
            this.hCaptcha.error = null;
            this.hCaptcha.verified = true;
            this.hCaptcha.token = token;
            this.hCaptcha.eKey = ekey;
            // console.log(`Callback token: ${token}, ekey: ${ekey}`);
        },
        onExpire() {
            this.hCaptcha.verified = false;
            this.hCaptcha.token = null;
            this.hCaptcha.eKey = null;
            this.hCaptcha.expired = true;
            console.log('Expired');
        },
        onChallengeExpire() {
            this.hCaptcha.verified = false;
            this.hCaptcha.token = null;
            this.hCaptcha.eKey = null;
            this.hCaptcha.expired = true;
            console.log(`Challenge expired`);
        },
        onError(err) {
            this.hCaptcha.token = null;
            this.hCaptcha.eKey = null;
            this.hCaptcha.error = err;
            // console.log(`Error: ${err}`);
        },
        onSubmit() {
            if (this.hCaptcha.verified == true && this.hCaptcha.expired == false) {
                return
            }
            // console.log('Submitting the invisible hCaptcha', this.$refs.invisibleHcaptcha);
            if (this.$refs.contactForm.validate()) {
                this.$refs.invisibleHcaptcha.execute();
            }
        },
        async submitMessage() {
            await fetch('https://dylan.blanque.com.ar/validate/',{ method: 'POST', 
            body: JSON.stringify({
                    name: this.contact.name,
                    email: this.contact.email,
                    subject: this.contact.topic,
                    message: this.contact.message,
                    token: this.hCaptcha.token
                })
            }).then(async res => {
                var json = await res.json()
                var code = json.status
                if(code == 0){
                    this.error = false
                }
                else{
                    this.error = true;
                    this.errorMsg = this.$t('codes.'+code)
                    if(code == -99)
                        this.disable = true
                }
                this.loading = false
                this.submitted = true;
                if (!this.error) {
                    this.closeDialog(true);
                }
            }).catch(e => {
                console.log(e)
                this.loading = false;
                this.submitted = true;
                this.error = true;
                this.errorMsg = this.$t('codes.-2')
            })
        }
    },
    mounted(){
    },
    components: { 
        VueHcaptcha
    }
}
</script>