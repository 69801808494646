<template>
<v-card flat class="transparent">
    <v-parallax class="mb-5" id="peomParallax" src="static/img/dylan-blanque-peom-allchars.jpg">
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
            <v-icon color="primary" class="mr-3">mdi-rotate-3d</v-icon>
            <h2 class="pa-0 ma-0 mr-3">{{ $t("category.3dAnimation").toUpperCase() }}</h2>
        </v-row>
        <v-row justify="center" align="center">
            <v-col class="px-12" cols="12" lg="8" xl="6">
                <p v-html="$t('sections.3dAnimation.description')"></p>
            </v-col>
        </v-row>

        <!-- General -->
        <v-row justify="center" align="center" :class="($vuetify.breakpoint.mdAndDown ? 'pb-6' : '') + ' ma-0 pa-0'">
            <v-col class="py-0" cols="12" md="6" lg="4">
                <v-btn rounded class="bg-primary text-white" target="_blank" href="https://www.artstation.com/dblanque">
                    <v-icon class="mr-3">mdi-artstation</v-icon>
                    <span>{{ $t('sections.3dAnimation.ASChannel') }}</span>
                </v-btn>
            </v-col>
            <v-col class="py-0" cols="12" md="6" lg="4">
                <v-btn rounded class="bg-primary text-white" target="_blank" href="https://www.youtube.com/c/Splitmatter/featured">
                    <v-icon class="mr-3">mdi-youtube</v-icon>
                    <span>{{ $t('sections.3dAnimation.YTChannel') }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-parallax>

    <v-row justify="center" align="center" v-if="ytFrames">
        <v-col cols="12" lg="4">
            <v-responsive :aspect-ratio="16/9">
                <v-fade-transition>
                    <iframe v-show="iframeArray[0].loaded" :src="iframeArray[0].url" @load="setIframeAsLoaded(0)" title="Behind the Scenes: Normandy CGI Cutscenes" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-fade-transition>
            </v-responsive>
        </v-col>
        <v-col cols="12" lg="4">
            <v-responsive :aspect-ratio="16/9">
                <v-fade-transition>
                    <iframe v-show="iframeArray[1].loaded" :src="iframeArray[1].url" @load="setIframeAsLoaded(1)" title="PEOM Cutscenes Development Timelapse" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-fade-transition>
            </v-responsive>
        </v-col>
    </v-row>

    <v-divider class="mt-4" style="max-width:60%; margin: auto;"></v-divider>

    <!-- XCOM 2 Mod -->
    <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
        <h2 class="pa-0 ma-0 mr-3">{{ $t("sections.3dAnimation.xcom2Mod.title").toUpperCase() }}</h2>
    </v-row>
    <v-row justify="center" align="center">
        <v-col class="px-12" cols="12" lg="8" xl="6">
            <p v-html="$t('sections.3dAnimation.xcom2Mod.description')"></p>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col cols="12">
            <v-carousel hide-delimiters show-arrows-on-hover :cycle="imageCategoryLoaded(xcomModImages) == xcomModImages.length">
                <v-carousel-item eager
                v-for="(item,i) in xcomModImages"
                :key="i">
                    <v-container fill-height fluid v-show="!item.loaded">
                    <v-row align="center" justify="center">
                        <v-progress-circular size="48" color="primary" indeterminate/>
                    </v-row>
                    </v-container>
                    <v-img
                        v-show="item.loaded"
                        :src="item.src"
                        @load="setImageAsLoaded(xcomModImages, i)" 
                        height="100%"
                    />
                </v-carousel-item>
                <template v-slot:prev="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                ><v-icon>mdi-chevron-double-left</v-icon></v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                ><v-icon>mdi-chevron-double-right</v-icon></v-btn>
                </template>
            </v-carousel>
        </v-col>
    </v-row>

    <v-divider class="mt-6" style="max-width:60%; margin: auto;"></v-divider>
    
    <!-- Mass Effect Mod -->
    <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
        <h2 class="pa-0 ma-0 mr-3">{{ $t("sections.3dAnimation.massEffectMod.title").toUpperCase() }}</h2>
    </v-row>
    <v-row justify="center" align="center">
        <v-col class="px-12" cols="12" lg="8" xl="6">
            <p v-html="$t('sections.3dAnimation.massEffectMod.description')"></p>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col cols="12">
            <v-carousel hide-delimiters show-arrows-on-hover :cycle="imageCategoryLoaded(meModImages) == meModImages.length">
                <v-carousel-item eager
                v-for="(item,i) in meModImages"
                :key="i">
                    <v-container fill-height fluid v-show="!item.loaded">
                    <v-row align="center" justify="center">
                        <v-progress-circular size="48" color="primary" indeterminate/>
                    </v-row>
                    </v-container>
                    <v-img
                        v-show="item.loaded"
                        :src="item.src"
                        @load="setImageAsLoaded(meModImages, i)"
                        height="100%"
                    />
                </v-carousel-item>
                <template v-slot:prev="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                ><v-icon>mdi-chevron-double-left</v-icon></v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                ><v-icon>mdi-chevron-double-right</v-icon></v-btn>
                </template>
            </v-carousel>
        </v-col>
    </v-row>
</v-card>
</template>

<style>
iframe {
    width: 100% !important;
    height: 100% !important;
}

#peomParallax .v-parallax__content {
    background: rgba(0,0,0,0.55);
}

#peomParallax img {
    margin-bottom: -100px;
}

@media screen and (min-width: 2560px) {
    #peomParallax img {
        margin-bottom: -400px;
    }
}

@media screen and (min-width: 2560px) {
    #peomParallax img {
        margin-bottom: -400px;
    }
}
</style>

<script>
export default {
    props:{
        continueLoading: Boolean
    },
    created() {
        document.documentElement.style.overflow = 'hidden';
        this.ytFrames = true
    },
    mounted() {
        document.documentElement.style.overflow = 'auto';
        this.xcomModImages[0].src = this.xcomModImages[0].path
        this.meModImages[0].src = this.meModImages[0].path
    },
    data() {
        return {
            ytFrames: false,
            iframesLoaded: 0,
            iframeArray: [
                {
                    index: 0,
                    loaded: false,
                    url: 'https://www.youtube.com/embed/Q-mtbStA_cY'
                },
                {
                    index: 1,
                    loaded: false,
                    url: 'https://www.youtube.com/embed/djcfiZ7KCyI'
                },
            ],
            xcomModImages: [
                {
                    path: 'static/img/halomod-1.png',
                    src: '',
                    loaded: false
                },
                {
                    path: 'static/img/halomod-2.png',
                    src: '',
                    loaded: false
                },
                {
                    path: 'static/img/halomod-3.png',
                    src: '',
                    loaded: false
                }
            ],
            meModImages: [
                {
                    path: 'static/img/memod-1.png',
                    src: '',
                    loaded: false
                },
                {
                    path: 'static/img/memod-2.png',
                    src: '',
                    loaded: false
                },
                {
                    path: 'static/img/memod-3.png',
                    src: '',
                    loaded: false
                },
                {
                    path: 'static/img/memod-4.png',
                    src: '',
                    loaded: false
                },
            ],
        }
    },
    watch: {
        // continueLoading(new_v){
        //     if (new_v === true) {
        //         console.log(new_v)
        //     }
        // }
    },
    methods: {
        getLazySrc(imgPath){
            return imgPath.replace("/img/","/img/lowres/")
        },
        imageCategoryLoaded(imageCategory) {
            let loaded_images = imageCategory.filter((v) => {
                if (v.loaded == true) return v
            })
            return loaded_images.length == imageCategory.length
        },
        allImageCategoriesLoaded(){
            return this.imageCategoryLoaded(this.meModImages) && this.imageCategoryLoaded(this.xcomModImages)
        },
        setImageAsLoaded(imageCategory, index){
            imageCategory[index].loaded = true
            if (index == 0 && !this.continueLoading) {
                this.$emit('onLoad');
                return
            }
            if (imageCategory[index+1] !== undefined)
                imageCategory[index+1].src = imageCategory[index+1].path
            if (index+1 == imageCategory.length && this.allImageCategoriesLoaded())
                this.$emit('onLoadFull')
        },
        setIframeAsLoaded(index){
            this.iframeArray[index].loaded = true;
            this.iframesLoaded++;
            if (this.iframesLoaded == this.iframeArray.length) {
                this.allIframesLoaded = true;
            }
        }
    }
}
</script>