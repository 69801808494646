<template>
<v-fade-transition v-if="loading == true">
    <v-overlay :opacity="overlayOpacity">
        <v-progress-circular :color="progressColor" size="150" width="8" indeterminate>
        </v-progress-circular>
    </v-overlay>
</v-fade-transition>
</template>

<script>
export default {
    props: {
        loading: Boolean,
        progressColor: {
            type: String,
            default: 'accent'
        },
        overlayOpacity: {
            type: String,
            default: '0.5'
        }
    }
}
</script>