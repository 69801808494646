<template>
<v-card flat class="mt-4 transparent">
    <!------------------>
    <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
        <v-icon color="primary" class="mr-3">mdi-microphone-variant</v-icon>
        <h2 class="pa-0 ma-0 mr-3">{{ $t("category.singing").toUpperCase() }}</h2>
    </v-row>

    <v-divider class="mt-4 mb-2" style="max-width:60%; margin: auto;"></v-divider>

    <v-row justify="center" class="my-2">
        <v-col cols="8" lg="6">
            <p v-html="$t('sections.singing.description')"></p>
        </v-col>
        <v-col class="my-0 py-0" cols="12">
            <v-btn id="yt-channel-btn" rounded outlined href="https://www.youtube.com/channel/UC5epauogkY0RDBrux6tiu1w" target="_blank">
                <v-icon class="mr-2">mdi-youtube</v-icon>
                {{ $t("sections.singing.YTChannel") }}
            </v-btn>
        </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mt-6 py-6" id="singingVideoRow">
        <v-col cols="12" lg="5">
            <v-responsive :aspect-ratio="16/9">
                <v-fade-transition>
                    <iframe v-show="iframeArray[0].loaded" :src="iframeArray[0].url" @load="setIframeAsLoaded(0)" title="Lost in the Echo Vocal Cover" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-fade-transition>
            </v-responsive>
        </v-col>
        <v-col cols="12" lg="5">
            <v-responsive :aspect-ratio="16/9">
                <v-fade-transition>
                    <iframe v-show="iframeArray[1].loaded" :src="iframeArray[1].url" @load="setIframeAsLoaded(1)" title="Redemption Song Vocal Cover" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </v-fade-transition>
            </v-responsive>
        </v-col>
    </v-row>
</v-card> 
</template>

<style>
#singingVideoRow {
    background-color: var(--clr-secondary);
    transition: background-color var(--base-transition-speed-slow-1) ease-in-out;
}

[theme=dark] #singingVideoRow {
    background-color: hsl(var(--clr-secondary-hue), var(--clr-secondary-sat), 8%);
}

#yt-channel-btn, #yt-channel-btn *, #yt-channel-btn::before, #yt-channel-btn::after  {
    transition: all var(--base-transition-speed-fast-5) ease-in-out;
}
</style>

<script>
export default {
    created() {
        this.ytFrames = true
    },
    data() {
        return {
            iframesLoaded: 0,
            iframeArray: [
                {
                    index: 0,
                    loaded: false,
                    url: 'https://www.youtube.com/embed/x2kGpGh2Ep4'
                },
                {
                    index: 1,
                    loaded: false,
                    url: 'https://www.youtube.com/embed/yDXjnOItA5A'
                },
            ],
            viewIsLoading: false
        }
    },
    methods: {
        setIframeAsLoaded(index){
            this.iframeArray[index].loaded = true;
            this.iframesLoaded++;
            if (this.iframesLoaded == this.iframeArray.length) {
                this.allIframesLoaded = true;
                this.$emit('onLoad')
            }
        }
    },
    mounted() {
        if (this.ytFrames !== true) this.$emit('onLoad')
        document.documentElement.style.overflow = 'auto';
    }
}
</script>