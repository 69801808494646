<template>
<v-row class="MainComponentMargin pa-1 ma-1 mt-4 mb-12">
    <v-container class="text-white">
    <!-- Title -->
    <!-- <v-row align-content="center" justify="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
    <h2 class="pa-0 ma-0">
        <v-icon class="clr-primary mb-1 mr-1">mdi-emoticon-happy</v-icon>
        {{ $t("category.aboutMe").toUpperCase() }}
    </h2>
    </v-row> -->

    <!-- Body -->
    <v-row align-content="center" class="ma-0 pa-0" style="min-height: 450px;">
        <v-row class="mx-6 pt-4" align-content="center" justify="space-around">
        <v-col cols="0" lg="1" xl="1"></v-col>
        <v-col cols="12" lg="4" xl="1" align-self="center" style="max-width:fit-content;">
            <v-row justify="center" align="center" class="mb-3">
            <v-img id="avatar-img" class="" @load="setAvatarAsLoaded" eager
                src="static/img/pfp-ush-lar-c.png"
            />
            </v-row>
        </v-col>
        <v-col cols="9" lg="6" xl="4" align-self="center">
            <p v-html="$t('sections.aboutMe.paragraphIntro')"
            :class="(breakpointXS || breakpointSM || breakpointMD ? 'text-center' : 'text-left')" style="white-space: pre-line;">
            </p>
            <p v-html="$t('sections.aboutMe.paragraphQuote')" style="white-space:pre-line; text" class="ma-0">
            </p>
        </v-col>
        <v-col cols="0" xl="1"></v-col>
        </v-row>
    </v-row>
    <v-row align="center" justify="center">
        <v-col cols="12" md="auto">
            <v-btn target="_blank" outlined
                href="https://github.com/dblanque">
                <v-icon class="mr-2">mdi-github</v-icon>
                {{ $t("sections.aboutMe.github") }}
            </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn target="_blank" outlined
                href="https://www.artstation.com/dblanque">
                <v-icon class="mr-2">mdi-artstation</v-icon>
                {{ $t("sections.aboutMe.artstation") }}
            </v-btn>
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn target="_blank" outlined
                href="https://www.youtube.com/channel/UC5epauogkY0RDBrux6tiu1w">
                <v-icon class="mr-2">mdi-youtube</v-icon>
                {{ $t("sections.aboutMe.youtube") }}
            </v-btn>
        </v-col>
    </v-row>
    </v-container>
</v-row>
</template>
<script>

export default {
    props: {
    },
    components: { },
    data() {
        return {
        }
    },
    methods: {
        setAvatarAsLoaded(){
            this.$emit('avatarLoaded', true);
        }
    },
    mounted(){
    },
    computed:{
        breakpointXS() {
            if (this.$vuetify.breakpoint.xs)
                return true
            else
                return false
        },
        breakpointSM() {
            if (this.$vuetify.breakpoint.sm)
                return true
            else
                return false
        },
        breakpointMD() {
            if (this.$vuetify.breakpoint.md)
                return true
            else
                return false
        },
        breakpointLG() {
            if (this.$vuetify.breakpoint.lg)
                return true
            else
                return false
        },
        breakpointXL() {
            if (this.$vuetify.breakpoint.xl)
                return true
            else
                return false
        },
    }
}
</script>
<style>
</style>