<template>
  <div>
    <!-- Main Bar -->
    <v-toolbar :dark="!isThemeDark()" :light="isThemeDark()">
      <div class="d-flex align-center mt-1" style="min-width: 16ch;">
        <v-btn text id="main-app-bar-name" @click="goHome">
          DYLAN BLANQUÉ
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn text :class="(breakpointXS ? '' : 'mr-4') + ' py-5'" @click="openContactForm">{{ $t("appBar.contactMe") }}</v-btn>
      <LanguageSelector v-if="!breakpointXS" class=""
        @updateTabSliders="updateTabSliders"
        style="max-width: 25ch; min-width: 8ch;"
        />
      <ThemeChanger :buttonIsSmall="true" @darkMode="isThemeDark" class="ml-2"/>
    </v-toolbar>
    <v-toolbar class="elevation-0" :dark="!isThemeDark()" :light="isThemeDark()" v-if="breakpointXS">
      <v-spacer/>
      <LanguageSelector class=""
        @updateTabSliders="updateTabSliders"
        style="max-width: 25ch; min-width: 8ch;"
        />
      <v-spacer/>
    </v-toolbar>
    <!-- Tabs Bar -->
    <v-row justify="center" class="ma-0">
      <v-toolbar :dense="!breakpointXS && !breakpointSM" elevation="1" id="tabs-nav-bar" :dark="!isThemeDark()" :light="isThemeDark()" 
      :class="' ' + (isThemeDark() ? 'bg-secondary bg-lig-10' : 'bg-secondary bg-lig-20')">
      <v-fade-transition>
        <v-tabs 
          v-model="active_tab"
          v-if="showTabs"
          color="primary" 
          slider-size="4"
          center-active
          centered
          show-arrows>
          <v-tab class="px-4" v-for="item in items" :key="item.index" @click="goto(item.index)" :disabled="!item.enabled">
            <v-icon class="hidden-md-and-down mr-2">{{ item.icon }}</v-icon>
            <span v-if="breakpointXS">
              {{ $t(item.title + "Short") }}
            </span>
            <span v-else>
              {{ $t(item.title) }}
            </span>
          </v-tab>
        </v-tabs> 
      </v-fade-transition>
      </v-toolbar>
    </v-row>
  </div>
</template>

<script>
import LanguageSelector from "./LanguageSelector.vue"
import ThemeChanger from "./ThemeChanger.vue"

export default {
    props:{
      selectedTab: Number
    },
    data() {
        return {
            showTabs: false,
            active_tab: 0,
            items: [
              {
                index: 0,
                title: "category.aboutMe",
                enabled: true,
                icon: "mdi-account",
              },
              {
                index: 1,
                title: "category.techServices",
                enabled: true,
                icon: "mdi-server",
              },
              {
                index: 2,
                title: "category.3dAnimation",
                enabled: true,
                icon: "mdi-rotate-3d",
              },
              {
                index: 3,
                title: "category.singing",
                enabled: true,
                icon: "mdi-microphone-variant",
              },
              // {
              //   index: 4,
              //   title: "category.voiceActing",
              //   enabled: false,
              //   icon: "mdi-microphone",
              // },
            ],
        }
    },
    mounted: function(){
      setTimeout(() => {  this.showTabs = true; }, 250);
      this.active_tab = this.selectedTab;
    },
    components: {
        LanguageSelector,
        ThemeChanger
    },
    methods:{
      goHome(){
        this.goto(0);
      },
      updateTabSliders(){
        this.showTabs = false
        setTimeout(() => {  this.showTabs = true; }, 250);
      },
      isThemeDark(){
          if (this.$vuetify.theme.dark == true) {
            return true
          }
          return false
      },
      openContactForm(){
        this.$emit('openContactForm');
      },
      goto: function (index) {
        if (this.items[index] != this.active_tab) {
          this.active_tab = index
          setTimeout(()=>{
            this.$emit('updateTab', index);
          }, 25)
        }
      }
    },
    computed:{
      breakpointXS() {
          if (this.$vuetify.breakpoint.xs)
              return true
          else
              return false
      },
      breakpointSM() {
          if (this.$vuetify.breakpoint.sm)
              return true
          else
              return false
      },
      breakpointMD() {
          if (this.$vuetify.breakpoint.md)
              return true
          else
              return false
      },
      breakpointLG() {
          if (this.$vuetify.breakpoint.lg)
              return true
          else
              return false
      },
      breakpointXL() {
          if (this.$vuetify.breakpoint.xl)
              return true
          else
              return false
      },
    }
}
</script>

<style>
#main-app-bar-name {
  font-family: Montserrat !important; 
  font-weight: 500 !important;
  /* cursor: default !important; */
}

[theme=dark] #tabs-nav-bar {
  border-bottom: hsl(var(--clr-secondary-hue), var(--clr-secondary-sat), 50%) 4px solid !important;
}

[theme=light] #tabs-nav-bar {
  border-bottom: hsl(var(--clr-secondary-hue), var(--clr-secondary-sat), 50%) 4px solid !important;
}

.sticky {
  position: sticky !important;
  top: 0 !important;
}

.max-width-90 {
  max-width: 90% !important;
}
</style>