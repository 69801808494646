<template>
<v-card flat class="mt-4 transparent">

    <!-----LOADING------>
    <LoadingOverlay :loading="viewIsLoading" style="border-radius: 0 !important;"/>
    <!------------------>

    <!------------------>
    <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
        <v-icon color="primary" class="mr-3">mdi-server</v-icon>
        <h2 class="pa-0 ma-0 mr-3">{{ $t("category.techServices").toUpperCase() }}</h2>
    </v-row>
    <v-divider class="mt-4" style="max-width:60%; margin: auto;"></v-divider>
    <!-- Tech Services Tab -->
    <!-- Title -->
    <v-row style="max-width:75%; margin: auto;">
        <h3 class="mt-8" style="min-width: 100% !important;">{{ $t("techServices.header") }}</h3>
        <!-- Body -->
        <v-expansion-panels accordion class="my-4 mt-10">
            <v-expansion-panel
            v-for="serviceItem in techServices"
            :key="serviceItem.index"
            class="tran-spd-fast-3"
            >
            <template v-if="serviceItem.type == 'standard'">
                <v-expansion-panel-header 
                :expand-icon="serviceItem.icon ? serviceItem.icon : undefined"
                :disable-icon-rotate="serviceItem.icon && serviceItem.iconRotate != true ? true : false"
                >
                    <h4>
                        {{ $t("techServices." + serviceItem.title + ".title") }}
                    </h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="mt-4 mx-2" justify="center" align-content="center" v-if="serviceItem.description">
                        <v-icon class="clr-primary mb-4 mr-1">mdi-information</v-icon>
                        <p class="text-center" v-html="$t('techServices.' + serviceItem.title + '.description')"></p>
                    </v-row>
                    <v-data-iterator hide-default-footer v-if="techServices[serviceItem.index].subItems.length > 0"
                    :items="techServices[serviceItem.index].subItems">
                        <template v-slot:default="props">
                            <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.index"
                                cols="12"
                                sm="12"
                                :md="serviceItem.index == 5 ? 12 : getColLength('md', props.items.length)"
                                :lg="serviceItem.index == 5 ? 12 : getColLength('lg', props.items.length)"
                                :xl="serviceItem.index == 5 ? 12 : getColLength('xl', props.items.length)"
                                class="px-1 pb-3"
                            >
                                <v-card class="tran-spd-fast-3 pa-4" flat>
                                <v-card-title class="subheading font-weight-bold" v-if="$t('techServices.' + serviceItem.title + '.' + item.text + '.title').length > 1">
                                    <v-row justify="center">
                                    <a target="_blank" v-if="item.url.length > 1" class="text-center" :href="item.url">
                                        <v-icon v-if="item.icon.length > 1">
                                            {{ item.icon }}
                                        </v-icon>
                                        {{ $t("techServices." + serviceItem.title + "." + item.text + ".title") }}
                                        <v-icon class="ml-1" small color="primary">
                                            mdi-open-in-new
                                        </v-icon>
                                    </a>
                                    <span v-else>
                                        <v-icon class="mr-1 mb-1 clr-primary" v-if="item.icon.length > 1">
                                            {{ item.icon }}
                                        </v-icon>
                                        {{ $t("techServices." + serviceItem.title + "." + item.text + ".title") }}
                                    </span>
                                    </v-row>
                                </v-card-title>

                                <v-divider class="mx-5" v-if="$t('techServices.' + serviceItem.title + '.' + item.text + '.title').length > 1">
                                </v-divider>

                                <v-card-text class="ma-0 pa-0 my-3 hover-blue font-weight-medium" v-for="line in item.lines" :key="line.lines">
                                    <v-icon small v-if="!item.disableChevronItems">
                                        mdi-chevron-right
                                    </v-icon>
                                    <span v-html="$t('techServices.' + serviceItem.title + '.' + item.text + '.line' + line)"></span>
                                </v-card-text>
                                </v-card>
                                
                            </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-expansion-panel-content>
            </template>
            <template v-else>
                <v-expansion-panel-header>
                    <h4>
                        {{ serviceItem.title }}
                    </h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                </v-expansion-panel-content>
            </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
    <!------------------>
</v-card>
</template>

<style>
.v-card a.external::after {
    margin-left: 0.5ch;
    margin-right: 0.5ch;
    content: "\F03CC";
    font-family: "Material Design Icons";
}
.hover-blue,
.hover-blue .v-icon {
    transition: color var(--base-transition-speed-fast-3) ease-in-out !important;
}

.hover-blue:hover,
.hover-blue:hover .v-icon {
    color: var(--clr-secondary) !important;
}

.hover-blue .v-icon {
    color: var(--clr-accent) !important;
}

.hover-blue:hover .v-icon {
    color: var(--clr-primary) !important;
}

a:hover {
    color: hsl(var(--clr-primary-hue), var(--clr-primary-sat), 40%) !important;
    transition: color var(--base-transition-speed-fast-3) ease-in-out !important;
}
</style>

<script>
import LoadingOverlay from "./LoadingOverlay.vue";

export default {
    components: { LoadingOverlay },
    mounted(){
        this.viewIsLoading = false;
        this.$emit('onLoad')
    },
    data(){
        return{
            viewIsLoading: true,
            techServices: [
                {
                index: 0,
                icon: 'mdi-server-security',
                title: "infrastructure",
                type: 'standard',
                description: false,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, text: "letsEncrypt", icon: "mdi-key", url: "", lines: 1 },
                        { index: 1, text: "ansible", icon: "mdi-server-network", url: "", lines: 1 },
                        { index: 2, text: "oses", icon: "mdi-laptop", url: "", lines: 2 }
                    ]
                },
                {
                index: 1,
                title: "networking",
                icon: 'mdi-console-network',
                type: 'standard',
                description: false,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, text: "pfsense", icon: "", url: "https://www.pfsense.org/", lines: 3 },
                        { index: 1, text: "openvpn", icon: "", url: "https://openvpn.net/", lines: 4 }
                    ]
                },
                {
                index: 2,
                title: "services",
                icon: 'mdi-cog',
                iconRotate: true,
                type: 'standard',
                description: true,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, text: "ocsinv", icon: "", url: "", lines: 1 },
                        { index: 1, text: "parallelsras", icon: "", url: "", lines: 2 },
                        { index: 2, text: "truenas", icon: "", url: "", lines: 2 },
                        { index: 3, text: "sambaldap", icon: "", url: "", lines: 2 },
                        { index: 4, text: "sambacifs", icon: "", url: "", lines: 1 },
                        { index: 5, text: "development", icon: "", url: "", lines: 3 },
                    ]
                },
                {
                index: 3,
                title: "webServices",
                icon: 'mdi-web',
                type: 'standard',
                description: false,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, text: "nginx", icon: "", url: "https://www.nginx.com/", lines: 2 },
                        { index: 1, text: "zabbix", icon: "", url: "https://www.zabbix.com/", lines: 4 }
                    ]
                },
                {
                index: 4,
                title: "pve",
                icon: 'mdi-debian',
                type: 'standard',
                description: false,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, text: "general", icon: "", url: "", lines: 6 }
                    ]
                },
                {
                index: 5,
                title: "vuejs",
                icon: "mdi-vuejs",
                type: 'standard',
                description: false,
                descriptionInfoIcon: false,
                subItems: [
                        { index: 0, disableChevronItems: true, text: "general", icon: "", url: "", lines: 1 },
                        { index: 1, disableChevronItems: true, text: "thisSite", icon: "", url: "", lines: 1 }
                    ]
                },
                {
                index: 6,
                title: "python",
                icon: "mdi-language-python",
                type: 'standard',
                description: true,
                descriptionInfoIcon: true,
                subItems: []
                }
            ],
        }
    },
    methods: {
        getColLength(breakpointSize, itemAmount) {
            switch (breakpointSize) {
                case 'md':
                    if (itemAmount == 2) {
                        return 6;
                    }
                    if (itemAmount >= 3) {
                        return 6;
                    }
                    return 12;
                case 'lg':
                    if (itemAmount == 2) {
                        return 6;
                    }
                    if (itemAmount >= 3) {
                        return 4;
                    }
                    return 12;
                case 'xl':
                    if (itemAmount == 2) {
                        return 6;
                    }
                    if (itemAmount == 3) {
                        return 4;
                    }
                    if (itemAmount > 3) {
                        return 2;
                    }
                    return 12;
                default:
                    return 12;
            }
        }
    }
}
</script>