<template>
<v-card flat class="mt-4 pb-12 transparent">
    <!------------------>
    <v-row justify="center" align="center" class="pa-0 ma-0 mt-8" style="min-width: 100% !important;">
        <v-icon color="primary" class="mr-3">mdi-microphone</v-icon>
        <h2 class="pa-0 ma-0 mr-3">{{ $t("category.voiceActing").toUpperCase() }}</h2>
    </v-row>
    <v-divider class="mt-4" style="max-width:60%; margin: auto;"></v-divider>

</v-card> 
</template>

<script>
export default {
}
</script>