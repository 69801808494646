<template>
  <div class="home techServices animationVideogames singing voiceActing">

    <!-----LOADING------>
    <LoadingOverlay overlayOpacity="1"
      :loading="!timeoutOverlay && !fontsLoaded || (selectedTab == 0 && (!avatarImageLoaded || !heroImageLoaded))"
      style="border-radius: 0 !important;"/>
    <!------------------>

    <!-- Toolbar / Navigation Bar -->
    <MainAppBar class="" :selectedTab="selectedTab" @updateTab="updateSelectedTab" @openContactForm="openContactForm()"/>
    <!------------------------------>
    <v-stepper flat v-model="selectedTab" non-linear>
    <v-fade-transition>
      <v-stepper-items>
        <v-stepper-content ref="HelloWorldSection" id="HelloWorldSection" class="pa-0 ma-0 stepper-min-height stepper-tr-fix stepper-initial" step="0">
          <HelloWorldSection @avatarLoaded="setAvatarImageToLoaded"/>
        </v-stepper-content>

        <v-stepper-content class="pa-0 ma-0 stepper-min-height stepper-tr-fix" step="1">
          <TechServicesSection @onLoad="preloadMain()"
            v-if="loadOtherSections || selectedTab == 1"/>
        </v-stepper-content>

        <v-stepper-content class="pa-0 ma-0 stepper-min-height stepper-tr-fix" step="2">
          <AnimationSection @onLoad="preloadMain()"
            v-if="loadOtherSections || selectedTab == 2"
            :continue-loading="loadOtherSections"/>
        </v-stepper-content>

        <v-stepper-content class="pa-0 ma-0 stepper-min-height stepper-tr-fix" step="3">
          <SingingSection @onLoad="preloadMain()"
            v-if="loadOtherSections || selectedTab == 3"/>
        </v-stepper-content>

        <v-stepper-content class="pa-0 ma-0 stepper-min-height stepper-tr-fix" step="4">
          <VASection @onLoad="preloadMain()"
            v-if="loadOtherSections || selectedTab == 4"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-fade-transition>
  </v-stepper>

    <!-- Contact Form -->
    <v-dialog
      v-model="dialog_contactForm"
      width="700"
      transition="scroll-y-transition">
        <ContactForm @createSnackbar="showSnackbar" @closeDialog="closeContactForm()"/>
    </v-dialog>
    
    <v-slide-y-transition>
      <div class="text-center ma-2">
        <v-snackbar class="mb-12"
          v-model="snackbar"
          :color="snackbarColor"
          :class="snackbarClasses"
          :dark="!isThemeDark()" :light="isThemeDark()"
        >
          <v-icon class="mr-2">{{ snackbarIcon }}</v-icon>
          <span class="mt-1">
            {{ $t(snackbarMessage) }}
          </span>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              icon
              v-bind="attrs"
              @click="snackbar = false"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-slide-y-transition>

    <v-slide-x-transition>
      <v-footer dark v-if="selectedTab == 0 && $vuetify.breakpoint.mdAndUp && pageLoaded()" padless id="made-with-vue-footer" class="py-1 px-4 transparent">
        <v-spacer/>
        <span class="font-family-montserrat" style="font-size: 0.8em; opacity: 50%;">
          {{ $t("footer.madeWithVue") }}
        </span>
      </v-footer>
    </v-slide-x-transition>
    
    <!------------------>
    <v-footer padless id="home-footer" :dark="!isThemeDark()" :light="isThemeDark()" class="py-1">
      <v-divider class="mx-4"></v-divider>
      <span :class="'mx-4 ' + (breakpointXS || breakpointSM ? '' : 'text-caption')">
        {{ $t("footer.dylanCopyright") }}
      </span>
      <v-divider class="mx-4"></v-divider>
    </v-footer>
    <!-- Router Example -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <v-btn @click="goToScreenAbout"></v-btn> -->
  </div>
</template>

<script>
// @ is an alias to /src
import MainAppBar from '@/components/MainAppBar'
import ContactForm from '@/components/ContactForm'
import HelloWorldSection from '@/components/HelloWorldSection'
import TechServicesSection from '@/components/TechServicesSection'
import SingingSection from '@/components/SingingSection'
import AnimationSection from '@/components/AnimationSection'
import VASection from '@/components/VASection'
import LoadingOverlay from '@/components/LoadingOverlay'

export default {
  name: 'HomeView',
  data () {
    return {
      preloadUsed: false,
      timeoutOverlay: false,
      loadMainSection: false,
      loadOtherSections: false,
      snackbarMessage: "",
      snackbarIcon: "",
      snackbarColor: "",
      snackbarClasses: "",
      snackbar: false,
      avatarImageLoaded: false,
      heroImageLoaded: false,
      fontsLoaded: false,
      displayBody: true,
      selectedTab: 0,
      dialog_contactForm: false
    }
  },
  computed:{
    mainImagesLoaded() {
      return `${this.heroImageLoaded}|${this.avatarImageLoaded}`;
    },
    breakpointXS() {
        if (this.$vuetify.breakpoint.xs)
            return true
        else
            return false
    },
    breakpointSM() {
        if (this.$vuetify.breakpoint.sm)
            return true
        else
            return false
    },
    breakpointMD() {
        if (this.$vuetify.breakpoint.md)
            return true
        else
            return false
    },
    breakpointLG() {
        if (this.$vuetify.breakpoint.lg)
            return true
        else
            return false
    },
    breakpointXL() {
        if (this.$vuetify.breakpoint.xl)
            return true
        else
            return false
    },
  },
  components: {
    MainAppBar,
    ContactForm,
    HelloWorldSection,
    TechServicesSection,
    SingingSection,
    AnimationSection,
    VASection,
    LoadingOverlay
  },
  watch:{
    mainImagesLoaded(newVal) {
          if (this.loadOtherSections) return
          const [heroImageLoaded, avatarImageLoaded] = newVal.split('|');
          if (heroImageLoaded === 'true' && avatarImageLoaded === 'true')
            this.loadOtherSections = true
        },
  },
  methods: {
    pageLoaded(){
      return this.heroImageLoaded && this.avatarImageLoaded
    },
    showSnackbar(message, icon, color, snackbarClasses){
      this.snackbarMessage = message;
      this.snackbarIcon = icon;
      this.snackbarColor = color;
      this.snackbarClasses = snackbarClasses;
      this.snackbar = true;
      
      setTimeout(() => {
        this.snackbar = false;
        this.snackbarMessage = '';
        this.snackbarIcon = '';
        this.snackbarColor = '';
        this.snackbarClasses = '';
      }, 5000);
    },
    preloadMain() {
      if (this.preloadUsed === true) return
      this.preloadUsed = true
      if (!this.heroImageLoaded) {
        const image = new Image();
        image.src = 'static/img/about-me-bg-2.png';
        this.load(image.src).then(() => {
          this.heroImageLoaded = true
        });
      }
    },
    setHeroImageToLoaded() {
      setTimeout(() => {
        this.heroImageLoaded = true;
      }, 50);
    },
    setAvatarImageToLoaded() {
      setTimeout(() => {
        this.avatarImageLoaded = true
      }, 50);
    },
    load(src) {
      return new Promise((resolve, reject) => {
          const image = new Image();
          image.addEventListener('load', resolve);
          image.addEventListener('error', reject);
          image.src = src;
      });
    },
    updateSelectedTab(e) {
      if (this.selectedTab != e)
        this.displayBody = false
        this.selectedTab = e
        var routeToPush = ''
        switch (e) {
          case 1:
            routeToPush = 'techServices'
            break;
          case 2:
            routeToPush = 'animationVideogames'
            break;
          case 3:
            routeToPush = 'singing'
            break;
          case 4:
            routeToPush = 'voiceActing'
            break;
          default:
            routeToPush = ''
            break;
        }
        // console.log(this.$route.path)
        if (this.$route.path != '/' + routeToPush) {
          this.$router.push('/' + routeToPush)
        }
        if (this.selectedTab == 0) {
          this.preloadMain()
        }
        setTimeout(() => {  this.displayBody = true }, 500);
    },
    goToScreenAbout(){
      this.$router.push('about')
    },
    closeContactForm() {
      return this.dialog_contactForm = false;
    },
    openContactForm() {
      return this.dialog_contactForm = true;
    },
    isThemeDark(){
        if (this.$vuetify.theme.dark == true) {
          return true
        }
        return false
    }
  },
  created: function(){
    var tabNumber = 0;
    switch (this.$route.path) {
          case '/techServices':
            tabNumber = 1
            break;
          case '/animationVideogames':
            tabNumber = 2
            break;
          case '/singing':
            tabNumber = 3
            break;
          case '/voiceActing':
            tabNumber = 4
            break;
          case '/home':
            tabNumber = 0
            break;
          default:
            break;
    }
    // console.log(this.$route.path);
    this.selectedTab = tabNumber;
    this.updateSelectedTab(tabNumber);
    document.fonts.ready.then(() => {
      setTimeout(()=>{
        this.fontsLoaded = true;
      }, 25)
    });
    setTimeout(()=>{
        this.timeoutOverlay = true
        console.log('60 Seconds elapsed, force timeout for Loading Overlay.')
    }, 60e3)
  }
}
</script>

<style>

.stepper-min-height {
  --height-sub: 140px;
  min-height: calc(100vh - var(--height-sub));
}

.stepper-tr-fix {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
}

.stepper-initial {
  opacity: 100;
  animation: fadeIn 1s normal;
}

#home-footer{
  min-width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
}

#made-with-vue-footer{
  min-width: 100%;
  position: fixed;
  bottom: 4vh;
  z-index: 500;
}

#made-with-vue-footer {
  text-shadow:1px 1px 10px rgba(255,255,255,0.2), 1px 1px 10px rgba(255,255,255,0.2);
}

/* 
[theme=light] #made-with-vue-footer {
  text-shadow:1px 1px 10px rgba(0,0,0,0.1), 1px 1px 10px rgba(0,0,0,0.1);
} 
*/

#avatar-img{
  shape-outside: circle(50%);
  --avatar-size: clamp(200px, 40vw, 40ch);
  width: var(--avatar-size); 
  height: var(--avatar-size); 
  border: var(--clr-secondary) 2px solid; 
  transition: border var(--base-transition-speed); 
  border-radius: 50%;
}

.v-stepper {
  border-radius: 0 !important;
}

#HelloWorldSection {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
</style>
